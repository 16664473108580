import { Fragment, useState } from 'react'
import Text from '../../../../../core/utils/Text'
import {
	Background,
	CMargin,
	DeleteIcon,
	DText,
	Flex,
	IconWrapper,
} from '../../../../../styles/CommonStyles'
import { LineBreak } from '../../../../../styles/main/MainCommonStyles'
import { AddNetworkBtn } from '../../../../../styles/main/setting'
import Input from '../../../../common/input/Input'
import MotionFade from '../../../../common/utils/MotionFade'
import Tabbar from '../../../../common/tabs/Tabbar'
import Tooltip from '../../../../common/utils/Tooltip'
import ModalLayout from '../../../../layout/main/ModalLayout'
import AddNetworkModal from '../../../../modals/AddNetworkModal'

const NetworkUpdate = ({ coin, onDetailChange, onAddNetwork, onDeleteNetwork }) => {
	const tabs = ['depositList', 'withdrawList']
	const [activeTab, setActiveTab] = useState(1)
	const [addModal, setAddModal] = useState({ type: null, open: false, coin: null })

	const onInputChange = (key, value, listIdx) => {
		const data = {
			key,
			value,
			listIdx,
			type: tabs[activeTab - 1],
		}
		onDetailChange(data)
	}

	const onModalOpen = () => setAddModal({ open: true, type: tabs[activeTab - 1], coin })
	const onModalClose = () => setAddModal({ type: null, open: false, coin: null })

	const addNetwork = (network) => {
		onModalClose()
		onAddNetwork(network, tabs[activeTab - 1])
	}

	return (
		<MotionFade key={activeTab + coin}>
			{coin && (
				<>
					<CMargin margin='10px' />
					<Flex fw>
						<Tabbar active={activeTab} tabs={tabs} onTabClicked={(idx) => setActiveTab(idx)} />

						<AddNetworkBtn onClick={onModalOpen}>
							<Flex fw fh>
								<Text tid='add-network' />
							</Flex>
						</AddNetworkBtn>
					</Flex>
					{coin[tabs[activeTab - 1]]?.map((item, idx) => (
						<Fragment key={idx}>
							<CMargin margin='15px' />
							<Flex fw justify={'space-between'}>
								<DText main>{idx + 1} -</DText>
								<Tooltip content='delete-network'>
									<IconWrapper onClick={() => onDeleteNetwork(idx, tabs[activeTab - 1])}>
										<DeleteIcon size={18} />
									</IconWrapper>
								</Tooltip>
							</Flex>
							<Flex fw wrap justify='flex-start'>
								<Background bg='secondaryBg'>
									<Input
										value={item.network}
										label={'network'}
										onInputChange={(v) => onInputChange('network', v, idx)}
										minWidth='260px'
										width='20%'
									/>
									<Input
										value={item.min}
										label={'min'}
										onInputChange={(v) => onInputChange('min', v, idx)}
										minWidth='260px'
										width='20%'
										currency={coin.id}
										number
									/>
									<Input
										value={item.max}
										label={'max'}
										onInputChange={(v) => onInputChange('max', v, idx)}
										minWidth='260px'
										width='20%'
										currency={coin.id}
										number
									/>
									<Input
										value={item.feeFactor}
										label={'fee-factor'}
										onInputChange={(v) => onInputChange('feeFactor', v, idx)}
										minWidth='260px'
										width='20%'
										number
									/>
									<Input
										value={item.feeMax}
										label={'fee-max'}
										onInputChange={(v) => onInputChange('feeMax', v, idx)}
										minWidth='260px'
										width='20%'
										currency={coin.id}
										number
									/>
									<Input
										value={item.scanner}
										label={'scanner'}
										onInputChange={(v) => onInputChange('scanner', v, idx)}
										width='30%'
									/>
								</Background>
							</Flex>
							<LineBreak />
						</Fragment>
					))}
				</>
			)}
			<ModalLayout width='480px' onClose={onModalClose} open={addModal.open}>
				<AddNetworkModal details={addModal} onCancel={onModalClose} onSubmit={addNetwork} />
			</ModalLayout>
		</MotionFade>
	)
}

export default NetworkUpdate
