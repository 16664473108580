import { AnimatePresence, motion } from 'framer-motion'
import styled, { css } from 'styled-components'
import { FiMoreVertical } from 'react-icons/fi'
import { fadeVariants } from '../../../core/utils/theme'
import Text from '../../../core/utils/Text'

const FilterDropdown = ({
	options = [],
	onOptionClicked = () => {},
	right,
	left,
	top,
	bottom,
	minWidth,
	selected,
	open,
} = {}) => {
	const onOptionClick = (idx) => {
		onOptionClicked(idx)
	}

	return (
		<div style={{ position: 'relative', width: '100%' }}>
			<AnimatePresence exitBeforeEnter>
				{open && (
					<DropBody
						right={right}
						left={left}
						top={top}
						bottom={bottom}
						minWidth={minWidth}
						variants={fadeVariants}
						animate='in'
						initial='out'
						exit='out'
					>
						{options.map((item, idx) => (
							<Option
								onClick={() => onOptionClick(idx)}
								selected={selected?.includes(item)}
								key={item}
							>
								<Text tid={item} />
							</Option>
						))}
					</DropBody>
				)}
			</AnimatePresence>
		</div>
	)
}

const DropBody = styled(motion.div)`
	position: absolute;
	top: ${(props) => props.top || 'calc(100% + 5px)'};
	bottom: ${(props) => props.bottom && props.bottom};
	min-width: ${(props) => props.minWidth || '100%'};
	width: ${(props) => props.width && props.width};
	//min-height: 120px;
	padding: 8px;
	border-radius: 6px;
	border: 1px solid ${(props) => props.theme.color}20;
	box-shadow: 0 0 2px ${(props) => props.theme.shadow};
	background-color: ${(props) => props.theme.primaryBg};
	z-index: 10000;

	${(props) =>
		!props.theme.english &&
		css`
			left: 0;
		`};

	${(props) =>
		props.right &&
		css`
			right: ${(props) => props.right};
		`};

	${(props) =>
		props.left &&
		css`
			left: ${(props) => props.left};
		`};
`

const Option = styled.div`
	padding: 8px;
	margin: 8px 0;
	border-radius: 4px;
	cursor: pointer;
	color: ${(props) => props.theme.color};
	font-size: 0.9rem;

	${(props) =>
		props.selected &&
		css`
			background-image: linear-gradient(45deg, #1ce087 0%, #3b6978 70%);
			color: #000000;
		`};

	&:hover {
		background-color: ${(props) => props.theme.hover};
	}

	@media screen and (max-width: 1050px) {
		font-size: 0.8rem;
	}
`

const MoreIcon = styled(FiMoreVertical)`
	color: ${(props) => props.theme.color};
	margin: auto;
	cursor: pointer;
	transition: all 0.2s;

	&:hover {
		transform: scale(1.1);
	}

	&:active {
		transform: scale(0.9);
	}
`

export default FilterDropdown
