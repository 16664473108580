import styled, { css } from 'styled-components'
import { RiArrowDropDownLine, RiArrowDropUpLine } from 'react-icons/ri'

const SelectWrapper = styled.div`
	width: ${(props) => props.width || '100%'};
	height: ${(props) => props.height || '50px'};
	border-radius: 4px;
	justify-content: space-between;
	align-items: center;
	border: 1px solid ${(props) => props.theme.color}90;
	padding: ${(props) => (props.size === 'big' ? '0 16px' : '0 5px')};
	position: relative;
	cursor: pointer;
	display: flex;

	${(props) =>
		props.open &&
		css`
			border: 1px solid ${(props) => props.theme.active};
		`};
`

const MultiOptions = styled.div`
	display: flex;
	flex-wrap: wrap;
`

const OptionChip = styled.div`
	padding: 6px 8px;
	border-radius: 8px;
	margin: 5px;
	border: 1px solid ${(props) => props.theme.color}20;
	background-color: ${(props) => props.theme.mainBg};
	display: flex;
	justify-content: center;
	align-items: center;
`

const OpenIcon = styled(RiArrowDropDownLine)`
	color: ${(props) => props.theme.color};
	margin-top: 2px;
`

const CloseIcon = styled(RiArrowDropUpLine)`
	color: ${(props) => props.theme.color};
	margin-top: 2px;
`

const ChatsWrapper = styled.div`
	width: 100%;
	height: 100%;
	border-radius: 12px;
	border: 1px dashed ${(props) => props.theme.active}25;
	margin: 10px 0;
	overflow-y: auto;
`

const ChatSelectBtn = styled.div`
	width: 120px;
	height: 32px;
	border-radius: 26px;
	box-shadow: 0 0 2px ${(props) => props.theme.shadow};
	background-color: ${(props) => (props.active ? props.theme.active : props.theme.color)}90;
	cursor: pointer;
	transition: all 0.3s;
	margin: 0 10px;

	&:hover {
		background-color: ${(props) => (props.active ? props.theme.active : props.theme.color)};
	}
`

const ChatOption = styled.div`
	padding: 8px 12px;
	display: grid;
	background-color: ${(props) => (props.active ? props.theme.active : props.theme.dropdown)}80;
	border-radius: 6px;
	border: 2px solid ${(props) => props.theme.color}20;
	margin: 15px 10px;
	cursor: pointer;
	transition: all 0.3s;
	grid-template-columns: 20% 60% 20%;
	row-gap: 4px;

	&:hover {
		background-color: ${(props) => (props.active ? props.theme.active : props.theme.dropdown)};
	}

	${(props) =>
		props.active &&
		css`
			border: 2px solid ${(props) => props.theme.primary}60;
		`};
`

const SearchInput = styled.input`
	border: none;
	outline: none;
	font-size: 0.9rem;
	color: ${(props) => props.theme.color};
	background-color: transparent;
	width: 100%;
	height: 100%;
	padding: 0 16px;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;

	&:focus {
		background-color: ${(props) => props.theme.mainBg};
	}
`

export {
	MultiOptions,
	OpenIcon,
	CloseIcon,
	OptionChip,
	SelectWrapper,
	ChatsWrapper,
	ChatSelectBtn,
	ChatOption,
	SearchInput,
}
