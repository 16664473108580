import styled, { css } from 'styled-components'
import { Flex } from '../../CommonStyles'
import { motion } from 'framer-motion'
import { BiFilter, BiSortUp } from 'react-icons/bi'
import { FaLongArrowAltUp, FaLongArrowAltDown } from 'react-icons/fa'

export const FilterIcon = styled(BiFilter)`
	color: ${(props) => props.theme.primary};
`

export const AddFilterBtn = styled(Flex)`
	border: 1px solid ${(props) => props.theme.color}15;
	padding: 4px;
	border-radius: 4px;
	cursor: pointer;
	//width: 92px;
	min-width: 32px;
	justify-content: space-around;
`

export const DropFilterBody = styled(motion.div)`
	position: absolute;
	top: calc(100% + 5px);
	padding: 8px;
	border-radius: 6px;
	border: 1px solid ${(props) => props.theme.color}20;
	box-shadow: 0 0 2px ${(props) => props.theme.shadow};
	background-color: ${(props) => props.theme.primaryBg};
	z-index: 10000;

	${(props) =>
		props.theme.english &&
		css`
			left: ${(props) => (props.type === 'update' ? '0' : '160px')};
		`};

	${(props) =>
		!props.theme.english &&
		css`
			right: ${(props) => (props.type === 'update' ? '0' : '160px')};
		`};

	@media screen and (max-width: 768px) {
		${(props) =>
			props.theme.english &&
			css`
				left: 0;
			`};

		${(props) =>
			!props.theme.english &&
			css`
				right: 0;
			`};
	}
`

export const FilterBtn = styled.div`
	padding: 4px 12px;
	min-width: 64px;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 30px;
	border: 1px solid ${(props) => props.theme.color}10;
	color: ${(props) => (props.active ? '#000000' : props.theme.color)};
	border-radius: 4px;
	background-color: ${(props) => (props.active ? props.theme.active : 'transparent')};
	cursor: pointer;
	font-size: 0.8rem;
	margin: 0 4px;
`

export const ItemWrapper = styled.div`
	background-color: ${(props) => props.theme.tInputBg};
	padding: 4px 12px;
	border-radius: 4px;
	border: 1px solid ${(props) => props.theme.color}15;
	color: ${(props) => props.theme.color};
	font-size: 0.8rem;
	margin: 5px;
	display: flex;
	align-items: center;
	position: relative;

	${(props) =>
		props.sort &&
		css`
			background-color: #818f9f90;
		`};

	@media screen and (max-width: 768px) {
		padding: 2px 8px;
		font-size: 0.7rem;
	}
`

export const SearchBtn = styled.div`
	border: 2px solid ${(props) => props.theme.tInputBg};
	padding: 8px 24px;
	font-size: 0.9rem;
	color: #030303;
	background-color: ${(props) => props.theme.active}90;
	transition: all 0.3s;
	border-radius: 6px;
	cursor: pointer;
	margin: 0 5px;

	&:hover {
		background-color: ${(props) => props.theme.active};
	}

	@media screen and (max-width: 768px) {
		padding: 4px 18px;
	}
`

export const DeleteAllFilters = styled.div`
	border: 2px solid ${(props) => props.theme.tInputBg};
	border-radius: 6px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 10px;
	height: 32px;
	background-color: ${(props) => props.theme.tInputBg}90;
	color: ${(props) => props.theme.color};
	font-size: 0.8rem;
	padding: 4px 16px;
	cursor: pointer;

	&:hover {
		background-color: ${(props) => props.theme.tInputBg};
	}
`

export const FiltersOn = styled.div`
	width: 10px;
	height: 10px;
	border-radius: 50%;
	border: 1px solid ${(props) => props.theme.mainGreen};
	background-color: ${(props) => props.theme.mainGreen}80;
	cursor: pointer;
	margin: 5px;
`

export const SortIcon = styled(BiSortUp)`
	color: ${(props) => props.theme.color};
`

export const SortRow = styled.div`
	width: 90%;
	margin: 2px auto;
	padding: 6px 8px;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
`

export const SortHeader = styled(Flex)`
	width: 100%;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	justify-content: space-between;
	background-color: ${(props) => props.theme.secondaryBg};
	padding: 10px 8px;
	margin-bottom: 2px;
	border-bottom: 1px solid ${(props) => props.theme.color}20;
`

export const AscendingIcon = styled(FaLongArrowAltUp)`
	color: ${(props) => props.theme.mainGreen}90;
	${(props) =>
		!props.theme.english &&
		css`
			margin-left: 4px;
		`};
	${(props) =>
		props.theme.english &&
		css`
			margin-right: 4px;
		`};
`

export const DescendingIcon = styled(FaLongArrowAltDown)`
	color: ${(props) => props.theme.mainRed}90;
	${(props) =>
		!props.theme.english &&
		css`
			margin-left: 4px;
		`};
	${(props) =>
		props.theme.english &&
		css`
			margin-right: 4px;
		`};
`
