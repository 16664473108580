import { useEffect, useState } from 'react'
import {
	useSetAvailableCoins,
	useSetCoinStaticPrices,
} from '../../../../services/react-query/setting'
import { deepCopy, makeObjectFormattedNumber, stringToNumber } from '../../../../utils/common'
import { useQueryContext } from '../../../../contexts/query'
import { useLocation } from 'react-router-dom'

const availableFormat = {
	isStatic: false,
	order: { min: 0, max: 0, takerFeeMax: 0, takerFeeFactor: 0, makerFeeMax: 0, makerFeeFactor: 0 },
	otc: { min: 0, max: 0, takerFeeMax: 0, takerFeeFactor: 0, makerFeeMax: 0, makerFeeFactor: 0 },
	price: { buyMin: 0, buyMax: 0, sellMin: 0, sellMax: 0 },
	withdrawList: [],
	depositList: [],
}

export const AC_SETTING_TYPES = {
	ALL: 'all',
	DETAILS: 'details',
	PRICE: 'price',
	OTC: 'otc',
	ORDER: 'order',
	DEPOSIT_WITHDRAW: 'deposit-withdraw-fee',
}

const useAvailableCoinsSetting = (availableCoins, predefinedCoins, tokens, coinState) => {

    const location = useLocation()
    const search = new URLSearchParams(location.search)

    const { setToast } = useQueryContext()
    const [predefined, setPredefined] = useState([])

    const { mutate: setStaticPrice } = useSetCoinStaticPrices()


    useEffect(() => {
        if (predefinedCoins && availableCoins && tokens) {
            let temp = []
            const availableSymbols = availableCoins.map(item => item.id)
            predefinedCoins.forEach(pre => {
                if (!availableSymbols.includes(pre.id)) {
                    let formattedPre = deepCopy(pre)
                    formattedPre = { ...formattedPre, ...availableFormat }
                    temp.push(formattedPre)
                    availableSymbols.push(pre.id)
                }
            })
            tokens?.forEach(token => {
                if (!availableSymbols.includes(token.symbol)) {
                    let formattedPre = {
                        id: token.symbol,
                        fa: token.symbol,
                        symbol: token.symbol,
                        name: token.symbol,
                        isActive: false,
                        ...availableFormat
                    }
                    temp.push(formattedPre)
                    availableSymbols.push(token.symbol)
                }
            })
            setPredefined(temp)
        }
    }, [predefinedCoins, availableCoins, tokens])


    const initialState = {
        [AC_SETTING_TYPES.DETAILS]: false,
        [AC_SETTING_TYPES.PRICE]: false,
        [AC_SETTING_TYPES.ORDER]: false,
        [AC_SETTING_TYPES.OTC]: false,
        [AC_SETTING_TYPES.DEPOSIT_WITHDRAW]: false,
    }

    const openAllEditBoxes = () => {
        const temp = {}
        Object.keys(initialState).forEach(key => {
            temp[key] = true
        })
        setEditState(temp)
    }

    const [editState, setEditState] = useState(initialState)

    // modes -> open, close, all, add
    const [editMode, setEditMode] = useState('close')

    const {coin, setCoin} = coinState
    const formatCoin = (coin) => {
        return {
            ...coin,
            otc: makeObjectFormattedNumber(coin.otc),
            order: makeObjectFormattedNumber(coin.order),
            price: makeObjectFormattedNumber(coin.order),
            depositList: coin.depositList.map(item => makeObjectFormattedNumber(item)),
            withdrawList: coin.withdrawList.map(item => makeObjectFormattedNumber(item))
        }
    }

    useEffect(() => {
        if (predefined.length) {
            const coinName = search.get('coin')
            if (coinName) {
                const _coin = predefined.find(item => item.id === coinName)
                if (_coin) {
                    setCoin(formatCoin(_coin))
                    openAllEditBoxes()
                    setEditMode('add')
                }
            }
        }
    }, [predefined])

    const [deleteModal, setDeleteModal] = useState(false)
    const onDeleteModalOpen = () => setDeleteModal(true)
    const onDeleteModalClose = () => setDeleteModal(false)

    const onCoinsSet = () => {
        if (coin.isStatic) {
            setStaticPrice({
                coin: coin.id,
                buy: stringToNumber(coin.buyStatic),
                sell: stringToNumber(coin.sellStatic)
            })
        }
        setEditState(initialState)
        setEditMode('close')
    }
    const { mutate: updateAvailableCoins, isLoading: loading, isSuccess: coinsUpdated } = useSetAvailableCoins(onCoinsSet)

    const onCoinChange = (idx) => {
        const _coin = editMode === 'add' ? predefined[idx] : availableCoins[idx]
        setCoin(formatCoin(_coin))
    }

    const enterEditMode = (type) => {
        if (!coin && type !== 'add') {
            setToast({
                show: true,
                isError: true,
                message: 'select-coin-to-see-details'
            })
            return
        }

        switch (type) {
            case 'add':
                setCoin(null)
                setEditMode(type)
                openAllEditBoxes()
                break
            case 'all':
                setEditMode(type)
                openAllEditBoxes()
                break
            default:
                setEditMode('open')
                setEditState(state => ({ ...state, [type]: true }))
        }
    }

    const exitEditMode = () => {
        setEditMode('close')
        setEditState(initialState)

        if (editMode === 'add') {
            setCoin(null)
        } else {
            if (coin) {
                const _coin = availableCoins.find(item => item.id === coin.id)
                setCoin(formatCoin(_coin))
            }
        }
    }


    const onDetailChange = (data) => {

        /**
         * @param {string} value - value
         * @param {string} type - ac-settings-type
         * @param {string} key - key of the object that should change
         * @param {boolean} isBoolean - for isActive && isStatic
         * @param {number} listIdx - idx for deposit and withdraw list
         */
        const { value, type, key, isBoolean, listIdx } = data

        switch (type) {
            case AC_SETTING_TYPES.DETAILS:
                if (isBoolean) setCoin(state => ({ ...state, [key]: !state[key] }))
                else setCoin(state => ({ ...state, [key]: value }))
                break
            case AC_SETTING_TYPES.PRICE:
                const price = { ...coin.price }
                price[key] = value
                setCoin(state => ({ ...state, price }))
                break
            case AC_SETTING_TYPES.ORDER:
                const order = { ...coin.order }
                order[key] = value
                setCoin(state => ({ ...state, order }))
                break
            case AC_SETTING_TYPES.OTC:
                const otc = { ...coin.otc }
                otc[key] = value
                setCoin(state => ({ ...state, otc }))
                break
            case 'depositList':
                const depositList = deepCopy(coin.depositList)
                depositList[listIdx][key] = value
                setCoin(state => ({ ...state, depositList }))
                break
            case 'withdrawList':
                const withdrawList = deepCopy(coin.withdrawList)
                withdrawList[listIdx][key] = value
                setCoin(state => ({ ...state, withdrawList }))
                break
            default:
                break
        }
    }

    /**
     * @param {object} network - new network to add
     * @param {string} type - depositList, withdrawList
     */
    const onAddNetwork = (network, type) => {
        const _coin = deepCopy(coin)
        _coin[type].push(network)
        setCoin(_coin)
    }

    /**
     * @param {object} idx - network index to remove
     * @param {string} type - depositList, withdrawList
     */
    const onDeleteNetwork = (idx, type) => {
        const _coin = deepCopy(coin)
        _coin[type].splice(idx, 1)
        setCoin(_coin)
    }

    const fixNumbers = (coin) => {
        const temp = deepCopy(coin)

        const types = ['price', 'order', 'otc']
        types.forEach(type => {
            Object.keys(temp[type]).forEach(key => {
                const newValue = stringToNumber(temp[type][key])
                if (newValue !== null) {
                    temp[type][key] = newValue
                }
            })
        })

        const listTypes = ['depositList', 'withdrawList']
        listTypes.forEach(type => {
            temp[type].forEach((item, idx) => {
                Object.keys(item).forEach(key => {
                    if (key !== 'network' && key !== 'scanner') {
                        const newValue = stringToNumber(temp[type][idx][key])
                        if (newValue !== null) {
                            temp[type][idx][key] = newValue
                        }
                    }
                })
            })
        })
        return temp
    }

    const onSubmitChanges = () => {
        let coins = [...availableCoins]

        if (editMode === 'add') {
            coins.push(fixNumbers(coin))
        } else {
            const idx = coins.findIndex(item => item.id === coin.id)
            coins[idx] = fixNumbers(coin)
        }
        if (coin.isStatic) {
            if (!coin.buyStatic || !coin.sellStatic) {
                setToast({
                    message: 'fill-static-prices',
                    isError: true, show: true
                })
                return
            }
        }

		coins = coins.map((item) => {
			item.depositList = item.depositList.map((list) => {
				if (!list?.scanner?.length)
					list.scanner = 'https://blockchair.com/$ASSET/transaction/$TXID'

				return list
			})
			item.withdrawList = item.withdrawList.map((list) => {
				if (!list?.scanner?.length)
					list.scanner = 'https://blockchair.com/$ASSET/transaction/$TXID'

				return list
			})

			return item
		})

        updateAvailableCoins(coins)
    }

    const onDeleteAvailableCoin = () => {
        const newCoinSet = availableCoins.filter(item => item.id !== coin?.id)
        updateAvailableCoins(newCoinSet)
        setCoin(null)
    }

    useEffect(() => {
        if (coinsUpdated) onDeleteModalClose()
    }, [coinsUpdated])

    return {
        editState,
        coin,
        onCoinChange,
        enterEditMode,
        exitEditMode,
        editMode,
        onDetailChange,
        onAddNetwork,
        onDeleteNetwork,
        onSubmitChanges,
        loading,
        predefined,
        onDeleteAvailableCoin,
        deleteModal,
        onDeleteModalClose,
        onDeleteModalOpen
    }
}

export default useAvailableCoinsSetting
