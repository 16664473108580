import ListLayout from '../../components/layout/main/ListLayout'
import { Decoration, DText, Flex, Padding, PageCard } from '../../styles/CommonStyles'
import { fadeVariants } from '../../core/utils/theme'
import Text from '../../core/utils/Text'
import { ProfileInfoAnimate, ProfileInfoItem } from '../../styles/main/profile'
import { TextArea } from '../../styles/main/user'
import Input from '../../components/common/input/Input'
import ActionButton from '../../components/common/buttons/ActionButton'
import { useState, useEffect } from 'react'
import { useMainContext } from '../../core/contexts/main'
import { useQueryContext } from '../../core/contexts/query'
import { useCreateUser } from '../../core/services/react-query/user'
import { inputLength, numberInputTypes, validateProfileInfo } from '../../core/utils/validation'
import { formatAuthDate } from '../../core/utils/common'
import DatePicker from 'react-multi-date-picker'
import persian from 'react-date-object/calendars/persian'
import persian_fa from 'react-date-object/locales/persian_fa'
import transition from 'react-element-popper/animations/transition'
import DateInput from '../../components/common/input/DateInput'
import { MOBILE_SIZE } from '../../core/constants/common'
import { useWindowSize } from '../../core/hooks/common/useWindowSize'

const profileInfoInitial = {
	firstName: '',
	lastName: '',
	fatherName: '',
	birthDate: '',
	email: '',
	mobile: '',
	idNo: '',
	phone: '',
	zip: '',
	county: '',
	country: '',
	city: '',
	address: '',
}

const validationStatusInitial = {
	firstName: { valid: false, error: 'input-length-error' },
	lastName: { valid: false, error: 'input-length-error' },
	fatherName: { valid: false, error: 'input-length-error' },
	email: { valid: false, error: 'email-format-error' },
	mobile: { valid: false, error: 'mobile-format-error' },
	idNo: { valid: false, error: 'idNo-format-error' },
	phone: { valid: false, error: 'phone-format-error' },
	zip: { valid: false, error: 'zip-format-error' },
	county: { valid: false, error: 'input-length-error' },
	city: { valid: false, error: 'input-length-error' },
	address: { valid: false, error: 'input-length-error' },
}

const AddUser = () => {
	const { width } = useWindowSize()
	const { lang, theme } = useMainContext()
	const { setToast } = useQueryContext()

	const { mutate: createUser, isSuccess, isLoading } = useCreateUser()

	const [valid, setValid] = useState(false)
	const [profileInfo, setProfileInfo] = useState(profileInfoInitial)

	const [validationStatus, setValidationStatus] = useState(validationStatusInitial)

	useEffect(() => {
		let valid = true
		const keys = Object.keys(validationStatus)
		for (let i = 0; i < keys.length; i++) {
			const key = keys[i]
			if (!validationStatus[key].valid) {
				valid = false
				break
			}
		}
		setValid(valid)
	}, [profileInfo])

	const onInputValueChange = (key, value) => {
		setProfileInfo((state) => {
			const newState = { ...state }
			newState[key] = value
			return newState
		})

		setValidationStatus(validateProfileInfo(key, value, validationStatus))
	}

	const getNewProfilePayload = () => {
		const newProfile = {}
		newProfile.firstName = profileInfo.firstName
		newProfile.lastName = profileInfo.lastName
		newProfile.fatherName = profileInfo.fatherName
		newProfile.mobile = profileInfo.mobile
		newProfile.email = profileInfo.email
		newProfile.idNo = profileInfo.idNo
		newProfile.phone = profileInfo.phone
		newProfile.address = {
			country: 'ir',
			county: profileInfo.county,
			city: profileInfo.city,
			line: profileInfo.address,
			zip: profileInfo.zip,
		}

		if (typeof newProfile.birthDate) {
			const birth = profileInfo.birthDate
			const day = birth.day
			const month = birth.monthIndex + 1
			const year = birth.year
			if (day && month && year) {
				newProfile.birthDate = formatAuthDate({
					day,
					month,
					year,
				})
			}
		}

		return newProfile
	}

	const onCreateUser = () => {
		if (valid) {
			createUser(getNewProfilePayload())
		} else {
			setToast({
				show: true,
				isError: true,
				message: 'fill-input-errors',
			})
		}
	}

	useEffect(() => {
		if (isSuccess) {
			setProfileInfo(profileInfoInitial)
		}
	}, [isSuccess])

	return (
		<ListLayout>
			<PageCard>
				<ProfileInfoAnimate variants={fadeVariants} animate='in' exit='out' initial='out'>
					<Padding padding={'20px'}>
						<Flex fw wrap>
							{profileInfo &&
								Object.keys(profileInfo).map((key) => {
									const value = profileInfo[key]

									return (
										<ProfileInfoItem key={key}>
											{key === 'birthDate' && (
												<DatePicker
													calendar={lang === 'fa' && persian}
													locale={lang === 'fa' && persian_fa}
													value={value}
													onChange={(v) => onInputValueChange(key, v)}
													animations={[transition()]}
													render={<DateInput isProfile label={'birthDate'} />}
													className={`${theme === 'dark' && 'bg-dark custom-calendar'} teal ${
														width < MOBILE_SIZE && 'rmdp-mobile'
													}`}
													calendarPosition={lang === 'fa' ? 'left-top' : 'right-top'}
												/>
											)}

											{key === 'address' && (
												<TextArea
													width={'calc(100% - 14px)'}
													minHeight={'40px'}
													value={value}
													placeholder={lang === 'en' ? 'address' : 'آدرس'}
													onChange={(e) => onInputValueChange(key, e?.target?.value)}
												/>
											)}
											{key !== 'role' && key !== 'address' && key !== 'birthDate' && (
												<Input
													value={value}
													label={key}
													onInputChange={(v) => onInputValueChange(key, v)}
													valid={validationStatus[key].valid}
													error={!!value && validationStatus[key].error}
													code={numberInputTypes.includes(key)}
													maxLength={inputLength[key]}
												/>
											)}
										</ProfileInfoItem>
									)
								})}
						</Flex>
						<Flex fw>
							<Flex width='280px' style={{ margin: '30px 0' }}>
								<ActionButton active={valid} onClick={onCreateUser} loading={isLoading}>
									<Text tid={'create-user'} />
								</ActionButton>
							</Flex>
						</Flex>
					</Padding>
					<Flex fw wrap justify={'space-around'}></Flex>
				</ProfileInfoAnimate>
			</PageCard>
		</ListLayout>
	)
}

export default AddUser
