import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import { CFlex, Flex } from '../../CommonStyles'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { TbChevronsLeft } from 'react-icons/tb'
import { IoExitOutline } from 'react-icons/io5'

export const SideItemWrapper = styled(CFlex)`
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	//border-radius: 22px;
	transition: all 0.3s;

	${(props) =>
		props.open &&
		css`
			border-right: 1px solid ${(props) => props.theme.color}20;
			padding: 0 4px;
			height: auto;
			width: calc(100% + 15px);
			box-shadow: 4px 0 4px -4px rgba(0, 0, 0, 0.5);
		`};

	${(props) =>
		props.status === 'tablet' &&
		css`
			align-items: center;
		`};
`

export const SideWrapper = styled(motion.div)`
	width: ${(props) => {
		switch (props.status) {
			case 'desktop':
				return '250px'
			case 'tablet':
				return '100px'
			default:
				return 0
		}
	}};
	transition: all 0.3s;
	height: calc(100vh - 80px);
	background-color: ${(props) => props.theme.mainBg};
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
	outline: 1px solid ${(props) => props.theme.color}10;
	overflow-y: auto;
	overflow-x: hidden;
	padding: 20px;
	position: relative;
	z-index: 10;

	@media screen and (max-width: 768px) {
		padding: 0;
	}

	${(props) =>
		props.status === 'desktop' &&
		css`
			@media screen and (max-width: 768px) {
				width: 200px;
				padding: 10px;
			}
		`};
`

export const SideItem = styled(Flex)`
	justify-content: flex-start;
	cursor: pointer;
	width: 100%;
	height: ${(props) => props.status === 'desktop' && '50px'};
	margin: 8px 0;
	padding: ${(props) => {
		switch (props.status) {
			case 'desktop':
				return '6px 12px'
			case 'tablet':
				return '6px'
			default:
				return 0
		}
	}};
	color: ${(props) => props.theme.color};
	font-size: 1rem;
	transition: all 0.3s;
	border-bottom: 1px solid ${(props) => props.theme.color}10;

	@media screen and (max-width: 768px) {
		font-size: 0.8rem;
	}

	${(props) =>
		props.giga &&
		css`
			background-color: ${(props) => props.theme.secondaryBg};
			border-radius: 8px;
		`};

	&:hover {
		background-color: ${(props) => props.theme.tInputBg};
		border-radius: 8px;
		box-shadow: 2px 2px 2px #00000030;
	}

	${(props) =>
		props.active &&
		css`
			background-color: ${(props) => props.theme.tInputBg};
			border-radius: 8px;
			box-shadow: 2px 2px 2px #00000030;
		`};

	${(props) =>
		props.status === 'tablet' &&
		css`
			width: fit-content;
		`};
`

export const SideSubItem = styled(SideItem)`
	border-bottom: none;
`

export const LtrSideOpenIcon = styled(FiChevronRight)`
	color: ${(props) => props.theme.color};
	transition: all 0.3s;
	transform: rotate(0);
	margin: 0 4px;
	${(props) =>
		props.open &&
		css`
			transform: rotate(90deg);
		`};
`

export const RtlSideOpenIcon = styled(FiChevronLeft)`
	color: ${(props) => props.theme.color};
	transition: all 0.3s;
	transform: rotate(0);
	margin: 0 4px;

	${(props) =>
		props.open &&
		css`
			transform: rotate(-90deg);
		`};
`

export const OpenCloseWrapper = styled.div`
	width: 40px;
	height: 40px;
	border: 1px inset ${(props) => props.theme.color}15;
	background-color: ${(props) => props.theme.primaryBg};
	box-shadow: 0 0 2px ${(props) => props.theme.color}80;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;

	${(props) =>
		props.theme.english &&
		css`
			right: -20px;
		`};

	${(props) =>
		!props.theme.english &&
		css`
			left: -20px;
		`};

	top: 10px;
	border-radius: 50%;
	z-index: 11;
	cursor: pointer;

	@media screen and (max-width: 768px) {
		display: none;
	}
`

export const OpenCloseBtn = styled(TbChevronsLeft)`
	${(props) =>
		props.theme.english &&
		css`
			transform: rotate(180deg);

			${(props) =>
				props.status === 'desktop' &&
				css`
					transform: rotate(0);
				`};
		`};

	${(props) =>
		!props.theme.english &&
		css`
			transform: rotate(0deg);

			${(props) =>
				props.status === 'desktop' &&
				css`
					transform: rotate(180deg);
				`};
		`};

	transition: all 1s;
	color: ${(props) => props.theme.color};
`

export const SideHolder = styled.div`
	position: fixed;
	top: 80px;
	z-index: 100;

	${(props) =>
		props.theme.english &&
		css`
			left: 0;
		`};

	${(props) =>
		!props.theme.english &&
		css`
			right: 0;
		`};
`

export const ExitIcon = styled(IoExitOutline)`
	color: ${(props) => props.theme.mainRed};

	${(props) =>
		!props.theme.english &&
		css`
			margin-left: 5px;
		`};

	${(props) =>
		props.theme.english &&
		css`
			transform: rotate(180deg);
			margin-right: 5px;
		`};
`
