import styled, { css } from 'styled-components'
import { CFlex, Flex } from '../../CommonStyles'
import { FaRegEdit } from 'react-icons/fa'
import { MdDeleteOutline } from 'react-icons/md'
import { motion } from 'framer-motion'

export const ProfileWrapper = styled(CFlex)`
	width: 100%;
	height: 100%;
	padding: 60px 20px;
	align-items: flex-start;
	justify-content: flex-start;
`

export const ProfileHeadWrapper = styled(Flex)`
	flex-wrap: wrap;
	width: 100%;
	padding: 0 40px;
	align-items: flex-start;
`

export const ProfileAvatar = styled.div`
	width: ${(props) => (props.size === 'big' ? '128px' : '32px')};
	height: ${(props) => (props.size === 'big' ? '128px' : '32px')};
	border-radius: 50%;
	border: 1px solid ${(props) => props.theme.color};
	display: flex;
	justify-content: center;
	align-items: center;
`

export const NameAvatar = styled.div`
	width: 100%;
	height: 100%;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${(props) => props.theme.mainBg};
	font-size: ${(props) => (props.size === 'big' ? '2.5rem' : '1.1rem')};
	background-color: ${(props) => props.color};
`

export const ImageGetter = styled.input`
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	opacity: 0;
	top: 0;
	left: 0;
	cursor: pointer;
	outline: none;
`

export const AvatarEdit = styled(FaRegEdit)`
	cursor: pointer;
	color: ${(props) => props.theme.color};
`

export const ProfileInfoItem = styled.div`
	padding: 25px 20px 0 20px;
	width: ${(props) => props.width || '33%'};
	min-width: 320px;
	background-color: ${(props) => props.theme.mainBg};
`

export const BankWrapper = styled(CFlex)`
	min-width: 320px;
	width: 33%;
	margin: 10px;
	min-height: 220px;
	padding: 30px 0 15px 0;
	border: 1px solid ${(props) => props.theme.color}15;
	position: relative;
`

export const BankStatus = styled.div`
	color: ${(props) => (props.verified ? props.theme.mainGreen : props.theme.mainOrange)};
	font-size: 1rem;
`

export const ProfileInfoAnimate = styled(motion.div)`
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: flex-start;
`

export const CardsAnimate = styled(motion.div)`
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	margin: 20px 0;
`

export const UserStatus = styled.div`
	border-radius: 12px;
	padding: 4px 12px;
	border: 1px solid ${(props) => (props.active ? props.theme.mainGreen : props.theme.mainRed)}90;
	color: ${(props) => props.theme.color};
	font-size: 0.7rem;
	background-color: ${(props) => (props.active ? props.theme.mainGreen : props.theme.mainRed)}60;

	@media screen and (max-width: 1050px) {
		font-size: 0.55rem;
		padding: 4px 8px;
	}
`

export const DocumentStatus = styled.div`
	border-radius: 12px;
	padding: 4px 12px;
	color: ${(props) => props.theme.color};
	font-size: 0.7rem;
	background-color: ${(props) => (props.active ? props.theme.mainGreen : props.theme.mainOrange)}50;

	@media screen and (max-width: 1050px) {
		font-size: 0.55rem;
		padding: 4px 8px;
	}
`

export const ProfileImageBox = styled.div`
	width: 280px;
	border: 1px solid ${(props) => props.theme.color}15;
	padding: 2px;
	margin: 10px;
`

export const PreviewImage = styled.div`
	position: fixed;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	backdrop-filter: blur(10px);
	z-index: 1000;
`

export const RolesWrapper = styled.div`
	width: 100%;
	height: 240px;
	overflow-y: auto;
	padding: 10px 0;
	margin: 10px 0;
`

export const RoleSelectRow = styled.div`
	padding: 8px 12px;
	margin: 4px 0;
	background-color: ${(props) =>
		props.index % 2 ? props.theme.secondaryBg : props.theme.primaryBg};
	cursor: pointer;
	width: 100%;
	border-radius: 4px;

	transition: all 0.2s;

	${(props) =>
		props.selected &&
		css`
			background-color: #818f9f;
		`};

	${(props) =>
		props.role &&
		css`
			background-color: ${(props) => props.theme.mainGreen}60;
		`};

	&:hover {
		${(props) =>
			!props.selected &&
			css`
				background-color: ${(props) => props.theme.tInputBg};
			`};
	}
`
