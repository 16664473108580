import { CFlex, DText, Flex } from '../../../styles/CommonStyles'
import styled, { css } from 'styled-components'
import Text from '../../../core/utils/Text'
import { useWindowSize } from '../../../core/hooks/common/useWindowSize'
import { TABLET_SIZE } from '../../../core/constants/common'
import { useEffect, useRef } from 'react'
import Dropdown from '../dropdown/Dropdown'
import { CloseIcon, OpenIcon } from '../../../styles/components/common/select-styles'

const HorizontalTabbar = ({ tabs, activeTab, onTabChange, controlRef }) => {
	const { width } = useWindowSize()
	const firstRender = useRef(false)

	useEffect(() => {
		const activeTabRef = tabs[activeTab].ref
		if (activeTabRef.current) {
			const { offsetHeight, offsetTop } = activeTabRef.current
			const { style } = controlRef.current

			let top = offsetTop
			if (!firstRender.current) {
				firstRender.current = true
				top += activeTab * 5
			}
			style.setProperty('--highlight-height', `${offsetHeight}px`)
			style.setProperty('--highlight-y-pos', `${top}px`)
		}
	}, [activeTab, tabs, controlRef, onTabChange])

	const Controller = ({ open }) => {
		return (
			<ControllerWrapper>
				<Flex fw fh justify={'space-between'}>
					<DText main>
						<Text tid={tabs[activeTab].name} />
					</DText>
					{open ? <CloseIcon size={20} /> : <OpenIcon size={20} />}
				</Flex>
			</ControllerWrapper>
		)
	}

	return (
		<>
			{width && width > TABLET_SIZE ? (
				<Wrapper ref={controlRef}>
					<Controls fw fh align={'flex-start'} justify={'flex-start'}>
						{tabs.map((tab, idx) => {
							if (!tab.perm) {
								return <></>
							}
							return (
								<TabWrapper key={tab.name} ref={tab.ref}>
									<Input
										type='radio'
										value={tab.name}
										id={tab.name}
										name={'segment'}
										onChange={() => onTabChange(idx)}
										checked={idx === activeTab}
									/>
									<Text tid={tab.name} />
								</TabWrapper>
							)
						})}
					</Controls>
				</Wrapper>
			) : (
				<Dropdown
					options={tabs.map((item) => item.name)}
					onOptionClicked={onTabChange}
					Controller={Controller}
					width={'300px'}
					selected={tabs[activeTab].name}
				/>
			)}
		</>
	)
}

const Wrapper = styled.div`
	min-width: 180px;
	background-color: ${(props) => props.theme.primaryBg};
	padding: 0 10px;
	--highlight-width: auto;
	--highlight-y-pos: 0;

	${(props) =>
		!props.theme.english &&
		css`
			border-left: 1px solid ${(props) => props.theme.color}20;
		`};

	@media screen and (max-width: 768px) {
		min-width: 120px;
	}
`

const TabWrapper = styled.div`
	width: 100%;
	margin: 8px 0;
	position: relative;
	display: flex;
	align-items: center;
	padding: 10px;
	color: ${(props) => props.theme.color};
	font-size: 0.9rem;
`

const Input = styled.input`
	opacity: 0;
	margin: 0;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	position: absolute;
	width: 100%;
	cursor: pointer;
	height: 100%;
`

const Controls = styled(CFlex)`
	&::before {
		content: '';
		background-color: ${(props) => props.theme.tInputBg};
		border: 1px solid ${(props) => props.theme.color}20;
		box-shadow: 0 0 4px rgb(0, 0, 0, 0.05);
		border-radius: 4px;
		width: 100%;
		height: var(--highlight-height);
		transform: translateY(var(--highlight-y-pos));
		position: absolute;

		left: 0;
		z-index: 0;
		transition: transform 0.3s ease, width 0.3s ease;
	}
`

const ControllerWrapper = styled.div`
	width: 100%;
	height: 38px;
	margin: 15px 0;
	border-bottom: 1px solid ${(props) => props.theme.mainOrange}80;
	padding: 0 12px;
`

export default HorizontalTabbar
