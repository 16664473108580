import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import { FaSquare } from 'react-icons/fa'
import { MdDeleteOutline } from 'react-icons/md'
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io'

const fontSizes = {
	sb: { d: '1.6rem', t: '1.5rem', m: '1.1rem' },
	b: { d: '1.3rem', t: '1.1rem', m: '1rem' },
	m: { d: '1rem', t: '0.9rem', m: '0.8rem' },
	s: { d: '0.8rem', t: '0.7rem', m: '0.55rem' },
	ss: { d: '0.7rem', t: '0.7rem', m: '0.7rem' },
}

const MainBody = styled.div`
	background-color: ${(props) => props.theme.primaryBg};
	direction: ${(props) => (props.theme.english ? 'ltr' : 'rtl')};
	font-family: ${(props) => (props.theme.english ? 'sans-serif' : 'Vazir')};
	//// letter-spacing: ${(props) => (props.theme.english ? '1.2px' : '1px')};
	min-height: 100vh;
	width: 100%;
	overflow: hidden;
	padding-top: 80px;
`

const Body = styled.div`
	width: 100%;
	height: calc(100vh - 80px);
	overflow-y: auto;
	overflow-x: hidden;
	scrollbar-width: none;
	&::-webkit-scrollbar {
		display: none;
	}

	transition: padding 0.3s;
	${(props) =>
		props.theme.english &&
		css`
			padding-left: ${(props) => (props.status === 'desktop' ? '250px' : '100px')};
		`};

	${(props) =>
		!props.theme.english &&
		css`
			padding-right: ${(props) => (props.status === 'desktop' ? '250px' : '100px')};
		`};

	${(props) =>
		!props.hasSidebar &&
		css`
			padding: 0;
		`};

	@media screen and (max-width: 768px) {
		padding: 0;
	}

	padding-bottom: 30px;
`

const Flex = styled.div`
	display: flex;
	width: ${(props) => (props.width ? props.width : props.fw ? '100%' : 'unset')};
	height: ${(props) => (props.height ? props.height : props.fh ? '100%' : 'unset')};
	justify-content: ${(props) => props.justify || 'center'};
	align-items: ${(props) => props.align || 'center'};
	flex-wrap: ${(props) => props.wrap && 'wrap'};
	position: relative;
`

const CFlex = styled.div`
	display: flex;
	flex-direction: column;
	width: ${(props) => (props.width ? props.width : props.fw ? '100%' : 'unset')};
	height: ${(props) => (props.height ? props.height : props.fh ? '100%' : 'unset')};
	justify-content: ${(props) => props.justify || 'center'};
	align-items: ${(props) => props.align || 'center'};
	flex-wrap: ${(props) => props.wrap && 'wrap'};
	position: relative;
`

const Grid = styled.div`
	display: grid;
	width: ${(props) => (props.width ? props.width : props.fw ? '100%' : 'unset')};
	height: ${(props) => (props.height ? props.height : props.fh ? '100%' : 'unset')};
	grid-template-rows: ${(props) => props.gr || 'unset'};
	grid-template-columns: ${(props) => `repeat(${props.gc}, 1fr)`};
	grid-column-gap: ${(props) => props.cgap && props.cgap};

	@media screen and (max-width: 1050px) {
		grid-template-columns: ${(props) => `repeat(${props.gc / 2}, 1fr)`};
	}

	@media screen and (max-width: 768px) {
		grid-template-columns: ${(props) =>
			props.gcR
				? `repeat(${props.gcR}, 1fr)`
				: `repeat(${props.gc / 4 > 1 ? props.gc / 4 : 1}, 1fr)`};
	}

	//@media screen and (max-width: 480px) {
	//  grid-template-columns: repeat(1, 1fr);
	//}
`

const Background = styled.div`
	display: inherit;
	flex-direction: inherit;
	justify-content: inherit;
	flex-wrap: inherit;
	align-items: inherit;
	width: 100%;
	height: 100%;
	background-color: ${(props) => props.theme[props.bg]};
`

const Padding = styled.div`
	width: 100%;
	height: 100%;
	flex-direction: inherit;
	display: inherit;
	justify-content: inherit;
	align-items: inherit;
	position: inherit;
	flex-wrap: inherit;
	padding: ${(props) => props.padding};
`

const RMargin = styled.div`
	width: inherit;
	display: inherit;
	justify-content: inherit;
	align-items: inherit;
	position: inherit;
	margin: 0 ${(props) => props.margin};
`

const CMargin = styled.div`
	display: inherit;
	justify-content: inherit;
	align-items: inherit;
	position: inherit;
	margin: ${(props) => props.margin} 0;
`

const Margin = styled.div`
	width: inherit;
	height: inherit;
	display: inherit;
	justify-content: inherit;
	align-items: inherit;
	position: inherit;
	margin: ${(props) => props.margin};
`

const DText = styled.div`
	font-size: ${({ cFontSize, fontSize }) =>
		cFontSize ? cFontSize : fontSize ? fontSizes[fontSize].d : fontSizes.m.d};
	color: ${(props) => props.color && props.color};
	${(props) =>
		props.main &&
		css`
			color: ${(props) => props.theme.color};
		`};
	${(props) =>
		props.primary &&
		css`
			color: ${(props) => props.theme.primary};
		`};
	${(props) =>
		props.active &&
		css`
			color: ${(props) => props.theme.mainOrange};
		`};
	direction: ${(props) => props._type === 'number' && 'ltr'};
	// font-family: ${(props) => props.type === 'number' && 'monospace'};
	margin: ${(props) => props.margin && props.margin};

	// letter-spacing: 1.1px;

	@media screen and (max-width: 1050px) {
		font-size: ${({ cFontSize, fontSize }) =>
			cFontSize ? cFontSize : fontSize ? fontSizes[fontSize].d : fontSizes.m.t};
	}

	@media screen and (max-width: 768px) {
		font-size: ${({ cFontSize, fontSize }) =>
			cFontSize ? cFontSize : fontSize ? fontSizes[fontSize].d : fontSizes.m.m};
	}

	line-break: ${(props) => props.lineBreak || 'auto'};
`

const DLink = styled(DText)`
	cursor: pointer;
	&:hover {
		color: ${(props) => props.theme.clientMain};
	}
`

const Column = styled.div`
	display: flex;
	line-break: auto;
	padding: 0 4px;
	min-width: ${(props) => props.width && props.width};

	${(props) =>
		props.center &&
		css`
			justify-content: center;
			align-items: center;
		`};

	${(props) =>
		props.more &&
		css`
			justify-content: flex-end;
			align-items: center;
		`};

	${(props) =>
		props.avatar &&
		css`
			align-items: center;
		`};

	${(props) =>
		props.operation &&
		css`
			position: absolute;
			top: 22%;
			${(props) =>
				props.theme.english &&
				css`
					right: 8px;
				`};
			${(props) =>
				!props.theme.english &&
				css`
					left: 8px;
				`};
		`}
`

const MBtn = styled.div`
	min-width: 120px;
	min-height: 38px;
	width: ${(props) => props.width && props.width};
	height: ${(props) => props.height && props.height};
	background-color: ${(props) => (props.active ? props.theme.clientMain : props.theme.inActive)};
	border-radius: 6px;
	box-shadow: 0 0 4px ${(props) => props.theme.mainBg};
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 500;
	// letter-spacing: 1.2px;
	color: ${(props) => (props.active ? '#000' : props.theme.color)};
	font-size: 1rem;
	cursor: pointer;

	&:hover {
		background-color: transparent;
		color: ${(props) => props.theme.color};
		border: 2px solid ${(props) => (props.active ? props.theme.clientMain : props.theme.color)};
	}
`

const QrWrapper = styled.div`
	border-radius: 8px;
	background-color: ${(props) => props.theme.primary};
	padding: 6px;
	display: flex;
	justify-content: center;
	align-items: center;
`

const Row = styled.div`
  margin: 4px 0;
  border-bottom: 0;
  display: grid;
  grid-template-columns: ${(props) => props.cs};
  row-gap: 5px;
  color: ${(props) => props.theme.color};
  padding: ${(props) => props.padding || '20px 10px'};
  border-radius: 4px;
  font-size: 0.75rem;
  align-items: center;
  transition: all 0.3s;
  width: 100%;
  position: relative;

  ${(props) =>
		props.action &&
		css`
			background-image: linear-gradient(
				45deg,
				${({ action, theme }) => (action === 'active' ? theme.mainGreen : theme.mainRed)}10 20%,
				${(props) => props.theme.table} 60%
			);
		`};
  
  background-color: ${(props) =>
		props.index % 2 ? props.theme.secondaryBg : props.theme.primaryBg};

  ${(props) =>
		props.header &&
		css`
			background-color: transparent;
		`};

  ${(props) =>
		props.hasParty &&
		css`
			background-image: linear-gradient(
				45deg,
				#819f8230 0%,
				${(props) => (props.index % 2 ? props.theme.secondaryBg : props.theme.primaryBg)} 25%
			);
		`};
  
  ${(props) =>
		!props.header &&
		css`
			min-height: 78px;
		`};
  
  ${(props) =>
		props.currentOrder &&
		css`
			&::after {
				content: ' ';
				position: absolute;
				width: ${(props) => props.percent && props.percent}%;
				height: 100%;

				${(props) =>
					props.theme.english &&
					css`
						left: 0;
					`};
				${(props) =>
					!props.theme.english &&
					css`
						right: 0;
					`};

				bottom: 0;
				background-color: ${(props) =>
					props.type === 'buy' ? props.theme.mainGreen : props.theme.mainRed}10;
				/* background-color: ${(props) => props.theme.mainGreen}15; */
				border-radius: 4px;

				@media screen and (max-width: 1050px) {
					height: ${(props) => props.percent && props.percent}%;
					width: 100%;
				}
			}
		`};
  
  ${(props) =>
		!props.noHover &&
		css`
    &:hover{
      ${(props) =>
				!props.header &&
				css`
					background-color: ${(props) => props.theme.tInputBg};
					transform: scale(1.02);

					@media screen and (max-width: 1050px) {
						transform: scale(1);
					}
				`};
  `};
  };

  @media screen and (max-width: 1050px) {
    padding: 15px;
  };
`

const Tab = styled.div`
	cursor: pointer;
	min-width: 80px;
	border-bottom: 1px solid ${(props) => (props.active ? props.theme.clientMain : props.theme.color)};
	border-radius: 4px;
	${(props) =>
		props.theme.english &&
		css`
			border-right: 1px solid
				${(props) => (props.active ? props.theme.clientMain : props.theme.color)};
		`};
	${(props) =>
		!props.theme.english &&
		css`
			border-left: 1px solid
				${(props) => (props.active ? props.theme.clientMain : props.theme.color)};
		`};
	padding: 8px 16px;
	color: ${(props) => (props.active ? props.theme.clientMain : props.theme.color)};
	font-size: 1.1rem;

	@media screen and (max-width: 1050px) {
		font-size: 1rem;
	}

	@media screen and (max-width: 768px) {
		font-size: 0.9rem;
	}
`

const Absolute = styled.div`
	position: absolute;
	left: ${(props) => props.left};
	top: ${(props) => props.top};
	bottom: ${(props) => props.bottom};
	right: ${(props) => props.right};
	height: ${(props) => props.height || '100%'};
	width: ${(props) => props.width || '100%'};
`

const SubCard = styled.div`
	border-radius: 22px;
	padding: 40px 20px;
	background-color: ${(props) => props.theme.primaryBg};
	margin-top: ${(props) => props.top};
	border: 1px solid #00000015;
	box-shadow: 0 0 2px #00000025;
	position: relative;
	z-index: 10;
	min-height: 200px;

	display: flex;
	align-items: center;
	justify-content: center;
	width: 95%;

	margin-left: auto;
	margin-right: auto;

	& > div {
		//max-width: 720px;
		width: 100%;
	}

	@media screen and (max-width: 1050px) {
		//margin-top: 200px;
		padding: 20px;
		border-radius: 12px;

		${(props) =>
			props.noCard &&
			css`
				padding: 20px 8px;
			`};
	}
`

const FeeRow = styled(Row)`
	background-color: ${(props) => (props.idx % 2 ? props.theme.mainBg : props.theme.primaryBg)};
	border: 1px solid ${(props) => props.theme.color}15;

	@media screen and (max-width: 1050px) {
		width: 80%;
	}
	@media screen and (max-width: 768px) {
		width: 100%;
		flex-direction: row;
		min-width: 400px;
		margin: 0 auto;
	}
`

const SpinnerContainer = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${(props) => props.theme.primaryBg};
`

const Block = styled.div`
	display: block;
	text-align: center;
	width: 100%;
`

const IconWrapper = styled(Flex)`
	padding: ${(props) => props.padding || '10px'};
	border-radius: 12px;
	cursor: pointer;

	&:hover {
		background-color: ${(props) => props.theme.tInputBg};
	}

	@media screen and (max-width: 1050px) {
		padding: 6px;
	}
`

const FadeAnimationContainer = styled(motion.div)`
	width: 100%;
	background-color: inherit;
`

const MLink = styled.a`
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
`

const OnlyDesktop = styled.div`
	@media screen and (max-width: 768px) {
		display: none;
	}
`

const PageCard = styled.div`
	width: 100%;
	border-radius: ${(props) => props.borderRadius || '12px'};
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
	border: 1px solid ${(props) => props.theme.color}10;
	background-color: ${(props) => props.theme.mainBg};
	padding: ${(props) => props.padding || '10px 20px'};
	min-height: 400px;

	@media screen and (max-width: 768px) {
		padding: 8px;
	}
`

const Decoration = styled(FaSquare)`
	width: 10px;
	height: 10px;
	transform: rotate(45deg);
	margin: 0 10px;
	color: ${(props) => props.theme.mainOrange};
`

const DeleteIcon = styled(MdDeleteOutline)`
	cursor: pointer;
	color: ${(props) => props.theme.color}80;
	transition: all 0.25s;

	&:hover {
		color: ${(props) => props.theme.mainRed}80;
	}
`

const TableWrapper = styled.div`
	min-width: 1000px;
	height: 57vh;
	padding: 0 5px;
`

const TableBody = styled.div`
	width: 100%;
	display: inline-block;
	padding: 10px 15px;
	overflow: auto;
`

const DetailsLtr = styled(IoIosArrowForward)`
	color: ${(props) => props.theme.primary};
`

const DetailsRtl = styled(IoIosArrowBack)`
	color: ${(props) => props.theme.primary};
`

const Relative = styled.div`
	position: relative;
`

export {
	MainBody,
	IconWrapper,
	DText,
	Flex,
	CFlex,
	Background,
	Padding,
	MBtn,
	Margin,
	CMargin,
	RMargin,
	DLink,
	Grid,
	QrWrapper,
	Column,
	Row,
	Tab,
	Absolute,
	SubCard,
	FeeRow,
	SpinnerContainer,
	Block,
	FadeAnimationContainer,
	MLink,
	OnlyDesktop,
	PageCard,
	Decoration,
	Body,
	DeleteIcon,
	TableBody,
	DetailsLtr,
	DetailsRtl,
	Relative,
	TableWrapper,
}
