import { AnimatePresence, motion } from 'framer-motion'
import styled, { css } from 'styled-components'
import { useEffect, useRef, useState } from 'react'
import { FiMoreVertical } from 'react-icons/fi'
import useClickOutside from '../../../core/hooks/common/useClickOutside'
import { Flex } from '../../../styles/CommonStyles'
import { fadeVariants } from '../../../core/utils/theme'
import Text from '../../../core/utils/Text'
import { useWindowSize } from '../../../core/hooks/common/useWindowSize'
import { TABLET_SIZE } from '../../../core/constants/common'
import CoinRow from '../common/CoinRow'

const Dropdown = ({
	options = [],
	onOptionClicked = () => {},
	right,
	left,
	top,
	bottom,
	Controller,
	width,
	minWidth,
	selected,
	isCoin,
} = {}) => {
	const { windowWidth } = useWindowSize()
	const ref = useRef()
	const [open, setOpen] = useState(false)
	useClickOutside(ref, () => setOpen(false))

	const onOptionClick = (idx) => {
		setOpen(false)
		onOptionClicked(idx)
	}

	const onStateChange = () => {
		setOpen((state) => !state)
	}

	return (
		<Flex width={width} ref={ref}>
			<Flex fw onClick={onStateChange}>
				{Controller ? (
					<Controller open={open} />
				) : (
					<MoreIcon size={windowWidth > TABLET_SIZE ? 24 : 18} />
				)}
			</Flex>
			<AnimatePresence exitBeforeEnter>
				{open && (
					<DropBody
						right={right}
						left={left}
						top={top}
						bottom={bottom}
						minWidth={minWidth}
						variants={fadeVariants}
						animate='in'
						initial='out'
						exit='out'
					>
						{options.map((item, idx) => (
							<Option
								onClick={() => onOptionClick(idx)}
								selected={selected === item}
								key={isCoin ? item.id : item}
							>
								{isCoin ? <CoinRow coin={item} /> : <Text tid={item} />}
							</Option>
						))}
					</DropBody>
				)}
			</AnimatePresence>
		</Flex>
	)
}

const DropBody = styled(motion.div)`
	position: absolute;
	top: ${(props) => props.top || '100%'};
	bottom: ${(props) => props.bottom && props.bottom};
	min-width: ${(props) => props.minWidth || '100%'};
	min-height: 120px;
	max-height: 300px;
	overflow-y: auto;
	padding: 8px;
	border-radius: 6px;
	border: 1px solid ${(props) => props.theme.color}20;
	box-shadow: 0 0 2px ${(props) => props.theme.shadow};
	background-color: ${(props) => props.theme.primaryBg};
	z-index: 10000000;

	${(props) =>
		props.theme.english &&
		css`
			right: 0;
		`};

	${(props) =>
		!props.theme.english &&
		css`
			left: 0;
		`};

	${(props) =>
		props.right &&
		css`
			right: ${(props) => props.right};
		`};

	${(props) =>
		props.left &&
		css`
			left: ${(props) => props.left};
		`};
`

const Option = styled.div`
	padding: 8px;
	margin: 8px 0;
	border-radius: 4px;
	cursor: pointer;
	color: ${(props) => props.theme.color};
	font-size: 0.9rem;

	${(props) =>
		props.selected &&
		css`
			background-image: linear-gradient(45deg, #1ce087 0%, #3b6978 70%);
			color: #000000;
		`};

	&:hover {
		background-color: ${(props) => props.theme.hover};
	}
`

const MoreIcon = styled(FiMoreVertical)`
	color: ${(props) => props.theme.color};
	margin: auto;
	cursor: pointer;
	transition: all 0.2s;

	&:hover {
		transform: scale(1.1);
	}

	&:active {
		transform: scale(0.9);
	}
`

export default Dropdown
