import styled from 'styled-components'
import { css } from 'styled-components'
import { formatNumber } from '../../../../../core/utils/common'
import Text from '../../../../../core/utils/Text'
import { CFlex, CMargin, DText, Flex, Padding } from '../../../../../styles/CommonStyles'

const BoundBox = ({ min = 0, max = 0, currency }) => {
	return (
		<BoundBoxWrapper>
			<Flex fw fh>
				<CFlex fh width='240px' justify='space-around'>
					<CMargin margin='0px' />
					<Flex fw>
						<Circle type='min'>
							<Text tid='min' />
						</Circle>
						<Circle type='max'>
							<Text tid='max' />
						</Circle>
						<Line />
					</Flex>
					<Flex fw justify='space-between'>
						<Padding padding='0 6px'>
							<DText main fontSize='s'>
								{formatNumber(min)} ({currency.toUpperCase()})
							</DText>
							<DText main fontSize='s'>
								{formatNumber(max)} ({currency.toUpperCase()})
							</DText>
						</Padding>
					</Flex>
				</CFlex>
			</Flex>
		</BoundBoxWrapper>
	)
}

const BoundBoxWrapper = styled.div`
	width: 100%;
	height: 82px;
	padding: 0 16px;
	filter: brightness(${(props) => (props.inActive ? '0.7' : '1')});
	direction: ltr;
	border-bottom: 1px solid ${(props) => props.theme.color}15;
`

const Line = styled.div`
	width: 220px;
	height: 8px;
	background-image: linear-gradient(90deg, #9f819e 0%, #819f82 100%);
`

const Circle = styled.div`
	border-radius: 50%;
	width: 42px;
	height: 42px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 0.8rem;
	color: ${(props) => props.theme.color};
	border: 1px solid ${(props) => props.theme.color}15;
	position: absolute;
	background-color: ${(props) => props.theme.mainBg};

	${(props) =>
		props.type === 'min' &&
		css`
			left: 0;
		`};
	${(props) =>
		props.type === 'max' &&
		css`
			right: 0;
		`};
`

export default BoundBox
